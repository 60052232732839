.button {
	margin-left: 2px;
}
/* TopBar.css */
.navbar {
	height: 50px; /* Set the height to minimal, adjust as needed */
	min-height: 50px; /* Ensures it doesn't shrink below this height */
	padding: 0.5rem 1rem; /* Optional: Adjust padding for better appearance */
}
.benfikotopnavigation {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #db617823;
  border-bottom: 1px solid rgba(255, 0, 0, 0.26);
}
.autostartopnavigation {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color:#b7b20063;
}
.benfikotopnavbar{
	background-color:white;
}
.autostartopnavbar{
	background-color: whitesmoke;
}
/* Responsive Design */
@media screen and (max-width: 768px) {
	.benfikotopnavigation,
	.autostartopnavigation {
	  flex-direction: column; /* Stack elements vertically on smaller screens */
	  align-items: flex-start; /* Align items to the start for better layout */
	  padding: 1rem; /* Adjust padding for better spacing on smaller screens */
	}
  
	.navbar {
	  height: auto; /* Allow height to adjust based on content on smaller screens */
	  min-height: auto; /* Allow it to grow or shrink based on content */
	}
  }
  
  @media screen and (max-width: 480px) {
	.benfikotopnavigation,
	.autostartopnavigation {
	  flex-direction: column; /* Stack items vertically on very small screens */
	  padding: 0.5rem; /* Adjust padding for compact layout */
	}
  
	.navbar {
	  height: auto; /* Allow navbar to adjust its height based on content */
	}
  }