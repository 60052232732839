
.imageitemcontact img{
    width: 50px;
}
.imagetebrendeve{
    display: flex;
    justify-content: flex-end;
    height: 100px;
}
.adresaorari{
    display: flex;
    justify-content: space-around;
    height: 400px;
  padding-top: 100px;
  background-color: white;
}
.adresapaorar{
    background-color: black;
    padding-left: 20%;
    padding-right: 20%;
    padding-top: 100px;
}
.nakontaktoni{
    color: white;
    font-family: 'Roboto';
  font-weight: bold;
  font-size: 50px;

}
.buttonatekontaktit{
    margin-right: 20px;
}

  
.pershkrimikontakt{
    color: white;
    margin-bottom: 60px;
    font-size: 18px;
}
/* App.css */
.separatorcontactus{
    content: "";
    display: block;
    position: relative;
    top: 50%;
    width: 10%;
    border-bottom: 5px solid white;
    margin-left: -30px;
    padding: 10px;
    margin-bottom: 20px;
  }
  .pershkrimiimages{
    display: flex;
  }
  .contactslash{
    height: 100px;
    margin-bottom: 60px;
  }
  .pershkrimmibuttonat{
    width: 50%;
  }
  .imagecarbrandcontct{
    text-align: center;
    margin-left: 10%;
  }
  .adresstitlecontact{
    font-weight: bold;
  }
  .oraripunestitlecontact{
    font-weight: bold;
  }
  .adresatitleadres{
    display: flex;
  }
  .adresaorari #titujtcontact{
    font-weight: bold;
    padding-right: 10px;
  }
  .adresametitujt{
    display: flex;
  }
 .adresaorari #titujtcontactnalt{
    padding-bottom: 20px;
  }