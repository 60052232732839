/* Chat Container Styling */
.chat-container {
  display: flex;
  flex-direction: column;

  width: 100%;
  max-height: 600px; /* Match this to the height */
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

/* Chat Messages Area */
.chat-messages {
  flex-grow: 1;
  padding: 10px;
  overflow-y: auto;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 400px; /* Set the desired height */
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 10px;
  background-color: #f9f9f9; /* Optional styling */
}
/* Chat Header */
.chat-header {
  background-color: #007bff;
  color: white;
  font-weight: bold;
  padding: 10px 15px;
  text-align: center;
  font-size: 16px;
  border-bottom: 1px solid #dee2e6;
}


/* Individual Message Bubble */
.chat-message {
  padding: 10px 15px;
  border-radius: 15px;
  max-width: 70%;
  font-size: 14px;
  line-height: 1.4;
  scroll-behavior: smooth; /* Smooth scrolling for better UX */
}

/* Received Messages */
.chat-message.sent {
  background-color: #f8d7da; /* Light red for received messages */
  align-self: flex-start; /* Align to the left */
  min-width: 200px;
}

/* Sent Messages */
.chat-message.received {
  background-color: #d1e7dd; /* Light green for sent messages */
  align-self: flex-end; /* Align to the right */
  min-width: 200px;
}

/* Chat Footer */
.chat-footer {
  display: flex;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #dee2e6;
  background-color: #f8f9fa;
}

/* Input Field */
.chat-footer input {
  flex-grow: 1;
  padding: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  outline: none;
  font-size: 14px;
}

/* Send Button */
.chat-footer button {
  margin-left: 10px;
  padding: 8px 15px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.chat-footer button:hover {
  background-color: #0056b3;
}
.message-metadata{
  font-size: smaller;
  text-align: end;
}
.message-text{
  font-size: large;
}