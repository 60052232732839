.search-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  /*position: fixed;*/
  top: 40px; /* Keeps it 100px from the top */
  left: 0;
  right: 0;
  z-index: 1000; /* Ensures it stays above other elements */
  /*background-color: white; /* Adds background for visibility */
  padding: 10px; /* Optional padding */
}

  
  .search-container {
    position: relative;
    width: 600px; /* Adjust based on your needs */
  }
  
  .search-input {
    width: 100%;
    padding: 8px;
    padding-right: 120px; /* Extra padding to accommodate the radio buttons */
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .radio-group {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    display: flex;
    gap: 10px;
  }
  
  .radio-label {
    font-size: 12px;
  }
  
  .radio-input {
    margin-right: 5px;
  }
  
  .search-button {
    padding: 8px 16px;
    font-size: 16px;
    color: white;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    z-index: 9;

  }
  
  .search-button:hover {
    background-color: #0056b3;
  }
  .benfikosearchbar{
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    /*position: fixed;*/
    top: 40px; /* Keeps it 100px from the top */
    left: 0;
    right: 0;
    z-index: 1000; /* Ensures it stays above other elements */
    /*background-color: white; /* Adds background for visibility */
    padding: 10px; /* Optional padding */
  }
  .autostarsearchbar{
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    /*position: fixed;*/
    top: 40px; /* Keeps it 100px from the top */
    left: 0;
    right: 0;
    z-index: 1000; /* Ensures it stays above other elements */
    /*background-color: white; /* Adds background for visibility */
    padding: 10px; /* Optional padding */
  }
  .search-dropdown {
    position: absolute;
    background: white;
    border: 1px solid #ccc;
    width: 100%;
    max-height: 250px;
    overflow-y: auto;
    list-style: none;
    padding: 0;
    margin: 0;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 10;
  }
  
  .search-dropdown li {
    padding: 8px 10px;
    cursor: pointer;
  }
  
  .search-dropdown li:hover {
    background-color: #f0f0f0;
  }
  
  /* Responsive Design */
@media screen and (max-width: 768px) {
  .search-container {
    width: 100%; /* Full width on small screens */
  }
  

  .search-input {
    display: flex;
    height: 70px;
    padding-right: 50px; /* Adjust padding for smaller screens */
    font-size: 14px; /* Smaller font size on mobile */
  }

  .radio-group {
    display: flex !important;
    flex-direction: column; /* Stack items vertically */
    right: 20px; /* Adjust positioning for smaller screens */
}


  .search-button {
    font-size: 14px; /* Smaller button font size */
  }
}

@media screen and (max-width: 480px) {
  .search-bar {
    flex-direction: column; /* Stack elements vertically on very small screens */
    gap: 15px;
    padding: 15px; /* Increase padding for better touch targets */
  }

  .search-container {
    width: 90%; /* Slightly reduced width for very small screens */
  }

  .search-input {
    display: flex;
    height: 70px;
    padding-right: 50px; /* Adjust padding for smaller screens */
    font-size: 14px; /* Smaller font size on mobile */
  }


  .radio-group {
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }

  .search-button {
    padding: 10px 20px; /* Larger button padding for easier clicks */
  }
}