.brands-grid{

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;

}
.brands-grid img{

  max-width: 100px;
  max-height: 100px;
  padding: 5px;
}
.th {
    color: grey;
}
.uljetitulli{

  max-width: 100%;
  min-width: 200px;
  text-align: center;
  border-bottom: 1px solid red;
}
/* Responsive Design */
@media screen and (max-width: 1080px) {
  .brands-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Change to 2 columns for medium screens */
  }

  .brands-grid img {
    max-width: 70px; /* Reduce image size for smaller screens */
    max-height: 70px;
  }

  .uljetitulli {
    font-size: 14px; /* Smaller font size for titles */
  }
}

@media screen and (max-width: 768px) {
  .brands-grid {
    display: none;
    grid-template-columns: repeat(2, 1fr); /* Change to 2 columns for medium screens */
  }

  .brands-grid img {
    display: none;
    max-width: 80px; /* Reduce image size for smaller screens */
    max-height: 80px;
  }

  .uljetitulli {
    display: none;
    font-size: 14px; /* Smaller font size for titles */
  }
}

@media screen and (max-width: 480px) {
  .brands-grid {
    display: none;
    grid-template-columns: 1fr; /* Stack items in a single column on very small screens */
  }

  .brands-grid img {
    display: none;
    max-width: 70px; /* Further reduce image size on small screens */
    max-height: 70px;
  }

  .uljetitulli {
    display: none;
    font-size: 12px; /* Even smaller font size for very small screens */
  }
}