.search-box-results {
	border: 1px solid #e6dddd; /* Light gray border */
	padding: 10px; /* Adds padding inside the box */
	border-radius: 5px; /* Optional: rounds the corners */
	width: 90%; /* Full width */
	background-color: #fff; /* Background color */
	margin: 5px; /* Margin around the box */
	display: flex; /* Enables flexbox */
	flex-wrap: wrap; /* Allows children to wrap */
	justify-content: space-between; /* Distributes items evenly */
}

.brand-image {
	/*flex: 0 0 10%; /* Takes up 10% of the width */
	display: flex;
}

.emri-article-oem {
	/*flex: 0 0 60%; /* Takes up 70% of the width */
	margin-left: 10px;
	width: fit-content;
	
}
.emri-article-oem h5 {
	/*flex: 0 0 60%; /* Takes up 70% of the width */
    word-break: break-word;
    max-width: 35ch;
    white-space: normal;

}

.cmimi {
	display: flex;
	justify-content: right;
	width: 100%;
	padding-right: 10px;
}
.price {
	position: relative;
	cursor: pointer;
  }
  
  .price:hover::after {
	content: attr(data-tooltip);
	position: absolute;
	top: 100%;
	left: -120%;
	transform: translateX(-50%);
	background-color: #333;
	color: #fff;
	padding: 5px;
	border-radius: 5px;
	white-space: nowrap;
	z-index: 1;
  }
  .cmimipakice{
	align-content: end;
  }
  

.searchresultprices {
    display: flex;
    flex-direction: column;
    text-align: right;
    margin-left: 10px;
	font-weight: bold;
}



.pershkrimi {
	/*flex-basis: 100%; /* Makes it take the full width */
	/*margin-top: 10px; /* Adds space above */
	/*padding: 10px; /* Optional padding */
	/*background-color: #f9f9f9; /* Optional background color */
	/*border: 1px solid #e6dddd; /* Optional border */
	/*border-radius: 5px; /* Optional rounded corners */
	/*margin-left: 0 0 10%;*/
	/*height: 25px;*/
	font-size: smaller;
	justify-content: center;
	align-items: center;
padding-left: 3px;
word-break: break-word;
max-width: 35ch; /* Adjust based on average word size */
white-space: normal;
/*word-wrap: normal;*/
}

.brand-image {
	/* display: flex; /* Enables flexbox for the brand images */
	justify-content: center; /* Centers content horizontally */
	align-items: center; /* Centers content vertically */
	/* margin-bottom: 10px; /* Optional: space below brand image section */
}
.search-brand,
.search-product-image {
	display: flex; /* Enables flexbox for each image container */
	justify-content: center; /* Centers images horizontally */
	align-items: center; /* Centers images vertically */
	margin: 0 10px; /* Optional: space between images */
}

.search-brand img {
	max-width: 100px; /* Ensures the image is responsive */
	/*max-height: 80px;*/
	/*height: auto; /* Maintains the aspect ratio */
	display: block; /* Prevents inline spacing issues */
}

.quantity-control {
	display: grid;
	align-items: center; /* Center items vertically */
}

.quantity-control input.text-center {
	width: 50px; /* Fixed width for the input */
	text-align: center; /* Center the input text */
	margin-right: 5px; /* Margin between input and buttons */
	border: 1px solid #ccc; /* Light gray border */
	border-radius: 4px; /* Rounded corners */
	font-size: 24px; /* Increase font size */
}
.input.spinner {
	width: 150px;
}

.search-buy-button {
	/*height: 40px;*/
	align-items: center !important;
	min-width: 70px;
	margin-left: 15px;
}
.button-minus {
	margin-right: 5px;
}
.search-objekti {
	padding: 3px;
	display: flex;
	margin-right: 15px;
}
.search-objekti img {
	width: 40px;
}
.search-sasia {
	padding: 3px;
	align-content: center;
}
.search-obj1-button {
	width: 40px;
}
.search-image-warehouse {
	width: 30px;
	padding: 2px;
}
.objekti-number {
	height: 15px;
	width: 15px;
	border: 1px solid #ccc; /* Light gray border */
	border-radius: 10px; /* Rounded corners */
	font-size: 8px;
	font-weight: bold;
	text-align: center;
	align-content: center;
	vertical-align: text-top;
	background-color: greenyellow;
	margin: 3px;
}
.objekti-info {
	display: flex;
	margin-right: 10px;
	height: 40px;
}
.objekti-number-group {
	display: block;

}
.totali-search{
	text-align: right;
	margin-right: 10px;
	font-weight: bold;
	color: blue;
	font-style: revert-layer;
	border-top: 0.1px dotted gray;
}
.amountzero{
color: white;

}
.searchoemgoogle{
	height: 10px;
	padding-right: 3px;
}
.oemnumberblock{
	display: flex;
  align-items: baseline;
}
.rezultatet-modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.8);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
  }
  
  .rezultatet-modal {
	background-color: white;
	padding: 20px;
	border-radius: 10px;
	box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
	position: relative;
	z-index: 1001;
  }
  
  .rezultatet-modal img {
	max-width: fit-content;
	height: auto;
	border-radius: 10px;
  }
  
  .rezultatet-close-modal {
	position: absolute;
	top: 10px;
	right: 10px;
	background-color: #0d6efd;
	color: white;
	border: none;
	border-radius: 5px;
	padding: 5px 10px;
	cursor: pointer;
	font-size: 14px;
  }
  
  .rezultatet-close-modal:hover {
	background-color: #0b5ed7;
  }
  .benfikobuybuttonclass{
	background-color: lightseagreen;
  }
  .autostarbuybuttonclass{
	background-color: lightseagreen !important;
  }
  .autostarbuybuttonclass:disabled{
	background-color: lightcoral !important;
  }
  .benfikobuybuttonclass:disabled{
	background-color: lightcoral !important;
  }
  /* Responsive Design */
@media screen and (max-width: 768px) {
	.search-box-results {
	  width: 100%; /* Full width on small screens */
	}
  
	.brand-image {
	  margin-bottom: 10px; /* Space below brand image */
	}
  
	.emri-article-oem {
	  width: 100%;
	  margin-left: 0;
	}
  
	.cmimi {
	  padding-right: 5px;
	}
  
	.pershkrimi {
	  font-size: 12px; /* Smaller font size on smaller screens */
	  max-width: 90%; /* Adjusted max-width */
	  font-size: smaller;
	  justify-content: center;
	  align-items: center;
  padding-left: 3px;
  word-break: break-word;
  max-width: 20ch; /* Adjust based on average word size */
  white-space: normal;
	}
  
	.quantity-control input.text-center {
	  width: 40px; /* Smaller input field */
	  font-size: 20px;
	}
  
	.search-buy-button {
	  font-size: 14px; /* Smaller button text */
	  padding: 8px 16px;
	}
  
	.objekti-number {
	  font-size: 6px;
	  height: 12px;
	  width: 12px;
	}
	.objekti-info {
		text-align: right;
		/*display: block; /* Ensures it takes full width */
		margin-left: 2%; /* Pushes it to the right */
		width: fit-content; /* Adjust width based on content */
	}
	.quantity-control #kolonat{
		text-align: right;
		width: auto;
	}
	
  }
  
  @media screen and (max-width: 480px) {
	.search-box-results {
	  padding: 8px; /* Reduced padding for smaller screens */
	}
  
	.brand-image img {
	  max-width: 80px; /* Smaller brand image on very small screens */
	}
  
	.search-buy-button {
	  min-width: 60px; /* Adjusted button width */
	  padding: 6px 12px;
	}
  
	.quantity-control input.text-center {
	  width: 30px; /* Even smaller input field */
	  font-size: 18px;
	}
  
	.pershkrimi {
	  font-size: 11px; /* Even smaller font size */
	}
  
	.objekti-info {
	  height: 30px; /* Reduced height for small screens */
	}
  
	.objekti-number {
	  font-size: 5px;
	  height: 10px;
	  width: 10px;
	}
  }