.go-to-top-button {
    position: fixed;
    bottom: 50px;
    right: 20px;
    width: 50px;
    height: 50px;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;
  }
  
  .go-to-top-button.hidden {
    opacity: 0;
    pointer-events: none; /* Prevent clicks when hidden */
  }
  
  .go-to-top-button.visible {
    opacity: 1;
  }
  