.image-item {
    margin-bottom: 10px;
    justify-content: center;
    align-items: center;
    display: flex;
  
    display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(6, auto);
  
      justify-content: center;
      align-items: center;
  }
  .carmodelkolona{
    flex: 1 1 0%;
    padding: 10px;
    width: 350px;
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);
  
    gap: 10px;
    align-content: center;
    align-items: center;
  
  }
  
  .image-item img {
    width: 100px;
    height: auto;
  }
  .brandstitle {
    max-width: 100%;
    min-width: 200px;
    text-align: center;
    border-bottom: 1px solid red;
  }
/* Responsive Design */
@media screen and (max-width: 1080px) {
  .car_logos{
    width: 80%;
  }
  .image-item {
    grid-template-columns: repeat(2, 1fr); /* Adjust grid to 2 columns on medium screens */
  }

  .image-item img {
    width: 80px; /* Reduce image size for smaller screens */
    height: auto;
  }

  .carmodelkolona {
    width: 90%; /* Full width for car models on smaller screens */
    grid-template-columns: repeat(1, 1fr); /* Stack items in 1 column */
  }

  .brandstitle {
    font-size: 14px; /* Smaller font size for titles on smaller screens */
  }
}
@media screen and (max-width: 768px) {
  .image-item {
    grid-template-columns: repeat(2, 1fr); /* Adjust grid to 2 columns on medium screens */
  }

  .image-item img {
    width: 80px; /* Reduce image size for smaller screens */
    height: auto;
  }

  .carmodelkolona {
    width: 100%; /* Full width for car models on smaller screens */
    grid-template-columns: repeat(1, 1fr); /* Stack items in 1 column */
  }

  .brandstitle {
    font-size: 14px; /* Smaller font size for titles on smaller screens */
  }
}

@media screen and (max-width: 480px) {
  .image-item {
    grid-template-columns: 1fr; /* Stack images in a single column on very small screens */
  }

  .image-item img {
    width: 60px; /* Further reduce image size on small screens */
    height: auto;
  }

  .carmodelkolona {
    width: 100%; /* Full width for car models on very small screens */
    grid-template-columns: repeat(1, 1fr); /* Stack items in 1 column */
  }

  .brandstitle {
    font-size: 12px; /* Even smaller font size for very small screens */
  }
}