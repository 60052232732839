.footerbottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #333; /* Keep dark background */
  color: #FFD700; /* Change text to Gold (or use #FFFFFF for White) */
  text-align: center;
  padding: 5px 0;
  font-size: 16px;
  transition: opacity 0.5s ease-in-out;
}

  
  @media (max-width: 768px) {
    .footerbottom {
      font-size: 14px; /* Smaller font size for tablets */
      padding: 8px 0;  /* Adjust padding */
    }
  }
  
  @media (max-width: 480px) {
    .footerbottom {
      font-size: 12px; /* Smaller font size for mobile */
      padding: 6px 0;  /* Further adjust padding */
    }
  }
  