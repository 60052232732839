.container-home {
	margin-top: 10px;
	display: flex;
	width: 100%;
	justify-content: space-evenly;
  }
  
  .left-column {
	flex: 0 0 15%;
	margin-right: 1%;
  }
  
  .middle-column {
	flex: 0 0 55%;
  }
  
  .right-column {
	flex: 0 0 15%;
	margin-left: 1%;
  }
  
  /* Responsive Design */
  
  /* For screens up to 1080px */
  @media screen and (max-width: 1080px) {
	.container-home {
		flex-direction: column; /* Stack columns vertically on smaller screens */
		align-items: center; /* Center align the columns */
	  }
	
	  .left-column,
	  .right-column {
		display: none; /* Hide left and right columns */
	  }
	
	  .middle-column {
		flex: 0 0 100%; /* Middle column takes full width */
		margin: 10px 0; /* Add vertical margin between columns */
	  }
	}
  /* For screens up to 768px */
  @media screen and (max-width: 768px) {
	.container-home {
	  flex-direction: column; /* Stack columns vertically on smaller screens */
	  align-items: center; /* Center align the columns */
	}
  
	.left-column,
	.right-column {
	  display: none; /* Hide left and right columns */
	}
  
	.middle-column {
	  flex: 0 0 100%; /* Middle column takes full width */
	  margin: 10px 0; /* Add vertical margin between columns */
	}
  }
  
  /* For screens up to 480px */
  @media screen and (max-width: 480px) {
	.container-home {
	  flex-direction: column; /* Stack columns vertically on very small screens */
	}
  
	.left-column,
	.right-column {
	  display: none; /* Hide left and right columns */
	  flex: 0 0 100%; /* Ensure they take full width */
	  margin: 5px 0; /* Adjust margin for tighter spacing */
	}
  
	.middle-column {
	  flex: 0 0 100%; /* Middle column takes full width */
	  margin: 5px 0; /* Adjust margin for tighter spacing */
	}
  }
  