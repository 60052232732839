.track-orders-container {
    padding: 2rem;
    width: 100%;
}


.track-orders-title {
    text-align: center;
    margin-bottom: 1.5rem;
    color: #343a40;
}

.order-card {
    overflow-x: auto;
    margin-bottom: 1.5rem;
}

.order-table {
    width: 100%;
    border-collapse: collapse;
}

.order-table thead {
    background-color: #343a40;
    color: #fff;
}

.order-table th,
.order-table td {
    text-align: center;
    padding: 0.75rem;
    border: 1px solid #dee2e6;
}

.order-table tbody tr:nth-child(even) {
    background-color: #f8f9fa;
}

.order-table tbody tr:hover {
    background-color: #e9ecef;
}

.order-table th {
    white-space: nowrap;
    text-align: center;
}

.text-red {
    color: red;
    font-weight: bold;
}

.text-green {
    color: green;
    font-weight: bold;
}
.sasitettrackorder{
    text-align: center !important;
}
.cmimitrackorder{
    text-align: right !important;
}