.menunavbar {
   /*background-color: #f30000a2; /* Light coral background */
    border-bottom: 1px solid rgb(238, 233, 233); /* Thin black line at the bottom */
}

.menunavbar .nav-link {
    color: rgb(245, 69, 69) !important; /* Set text color to white */
}

.menunavbar .nav-link.active {
    color: black !important; /* Set active link color to black */
}

.menunavbar .nav-link:hover {
    color: lightgray; /* Optional: Change color on hover */
}
.content-and-search {
    display: flex; /* Aligns Routes and SearchBar horizontally */
    align-items: center; /* Centers them vertically */
    gap: 10px; /* Space between Routes and SearchBar */
}

