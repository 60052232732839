.top-footer {
    width: 100%;
    background-color: #333;
    color: white;
    text-align: center;
    padding: 0px 0;
    font-size: 16px;
    transition: opacity 0.5s ease-in-out;
    position: relative;
  }
  
  .top-footer-text {
    display: flex;
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden;
    text-overflow: ellipsis; /* Show ellipsis if the text is too long */
    max-width: 100%; /* Prevent text from overflowing */
  }
  
  .scroll-text {
    animation: scroll 30s linear infinite; /* Makes the text scroll */
    padding-right: 2%;
  }
  .top-footer-noconnection {
    width: 100%;
    background-color: #9e0f0f;
    color: white;
    text-align: center;
    padding: 0px 0;
    font-size: 16px;
    transition: opacity 0.5s ease-in-out;
    position: relative;
  }
  
  .top-footer-text-noconnection {
    display: flex;
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden;
    text-overflow: ellipsis; /* Show ellipsis if the text is too long */
    max-width: 100%; /* Prevent text from overflowing */
  }
  
  .scroll-text-noconnection {
    animation: scroll 30s linear infinite; /* Makes the text scroll */
    padding-right: 2%;
  }

  @keyframes scroll {
    0% {
      transform: translateX(100%); /* Start off the screen from the right */
    }
    100% {
      transform: translateX(-100%); /* Move to the left until off-screen */
    }
  }
  