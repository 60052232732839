.history-container {
	display: flex;
	flex-direction: column;
	padding: 1rem;
    width:48%;
}

.filters-container {
	display: flex;
	gap: 1rem;
	align-items: center;
}

.year-input {
	width: 150px;
}

.months-container {
	display: flex;
	flex-wrap: wrap;
	gap: 0.5rem;
}

.tables-container {
	display: flex;
}

.primary-table {
    width: 75%;
    padding-right: 1rem;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
}

.detail-table {
    position: fixed;
    right: 1rem;
    width: 60%;
    max-height: 70%;
    overflow-y: auto;
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    padding: 1rem;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.detail-table h3 {
	margin-bottom: 1rem;
}

.table-active {
	background-color: #e9ecef !important;
}
/* Date Header Styling */
.date-header {
	position: relative;
    display: flex;
  align-items: end;
}

/* Button Styling */
.calendar-button {
	margin-left: 5px;
}

/* Calendar Dropdown */
.calendar-dropdown {
	position: absolute;
	top: 100%;
	left: 0;
	background-color: white;
	border: 1px solid #ccc;
	padding: 5px;
	z-index: 1000;
	box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
	border-radius: 5px;
}

/* Grid for Days */
.calendar-grid {
	display: flex;
	flex-wrap: wrap;
	width: 210px;
}

/* Individual Day Styling */
.calendar-day {
	width: 30px;
	height: 30px;
	line-height: 30px;
	text-align: center;
	cursor: pointer;
	margin: 2px;
	border: 1px solid #eee;
	border-radius: 3px;
	transition: background 0.3s;
}

.calendar-day:hover {
	background-color: #007bff;
	color: white;
}
.histordaybutton {
    padding-top: 5px;
}

