/* LanguageToggle.css */

.language-toggle-container {
    display: flex;
    justify-content: center; /* Align on the right */

  }
  
  .language-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
    margin: 0 5px;
  }
  
  .language-flag {
    width: 30px; /* Adjust the size as needed */
    height: auto;
  }
  
  /* Optional: Add hover effect */
  .language-button:hover {
    background-color: #f2f2f2;
    border-radius: 5px;
  }
  