.dropdown-menu{
  width: 500px;
}

.cart-container {
    padding: 1.5rem;
  }
  
  .cart-title {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .cart-empty-message {
    text-align: center;
    color: #6c757d;
  }
  
  .cart-items-list {
    margin-bottom: 1rem;
  }
  
  .cart-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
  }
  
  .cart-item-info h5 {
    font-size: 1.2rem;
  }
  .cartitemoemnr{
    margin-top: 0px;
  margin-bottom: 0px;

  }
  .cart-item-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .quantity-control-shoping-cart {
    display: flex;
    align-items: center;
  }
  
  .quantity-btn {
    width: 32px;
    height: 32px;
    font-size: 1rem;
    border: 1px solid #ddd;
    background-color: #f8f9fa;
  }
  
  .quantity-input {
    width: 50px;
    text-align: center;
    margin: 0 0.5rem;
  }
  
  .remove-btn {
    margin-top: 0.5rem;
    color: #dc3545;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .cart-item-total {
    font-weight: bold;
    margin-top: 0.5rem;
  }
  
  .cart-methods {
    display: flex;
    justify-content: space-between;
    margin-top: 1.5rem;
  }
  
  .method-card {
    width: 48%;
  }
  
  .method-select {
    width: 100%;
    padding: 0.5rem;
  }
  
  .checkout-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.5rem;
  }
  
  .total-price {
    font-size: 1.3rem;
    font-weight: bold;
    color: #007bff;
  }
  
  .checkout-btn {
    padding: 0.5rem 1.5rem;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
  }
  /* Responsive Design */
@media screen and (max-width: 768px) {
  .cart-container {
    padding: 1rem; /* Reduce padding on smaller screens */
  }

  .cart-item {
    flex-direction: row; /* Maintain horizontal layout */
    padding: 0.5rem;
  }

  .quantity-control-shoping-cart {
    flex-direction: row; /* Keep horizontal layout */
  }

  .cart-methods {
    flex-direction: row; /* Keep horizontal layout */
    align-items: stretch;
  }

  .method-card {
    width: 100%; /* Make each method card take full width */
    margin-bottom: 1rem;
  }

  .checkout-section {
    flex-direction: row; /* Maintain horizontal layout */
    align-items: center;
  }

  .checkout-btn {
    width: 100%; /* Make checkout button take full width */
  }
}

@media screen and (max-width: 480px) {
  .cart-title {
    font-size: 1.3rem; /* Slightly smaller title on very small screens */
  }

  .cart-item {
    padding: 0.5rem; /* Reduce padding for better spacing */
  }

  .quantity-btn {
    width: 28px; /* Smaller button size on very small screens */
    height: 28px;
    font-size: 0.9rem;
  }

  .quantity-input {
    width: 40px; /* Adjust input field size */
  }

  .total-price {
    font-size: 1.1rem; /* Smaller font size for total price */
  }

  .checkout-btn {
    padding: 0.5rem 1rem;
    font-size: 1rem;
  }
}